-
<template>
  <div>
    <b-modal
      id="order-modal"
      modal-class="modal-class"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
    >
      <button class="close-btn" @click="hideOrderModal">
        <i class="fa fa-times"></i>
      </button>
      <div class="order-type">
        <div class="text-value">Order</div>
      </div>
      <div class="d-block text-center p-3 order-heading">
        <h3>
          Order #{{ orderDetails.orderId }}:
          {{ orderDetails.customer.name }}
        </h3>
      </div>

      <div class="order-info-details">
        <div class="info-block user-info">
          <img
            v-bind:src="
              orderDetails.riderId.profileImage || '/media/users/user1.jpg'
            "
            alt="user image"
          />
          <span class="user-name">{{ orderDetails.riderId.name }}</span>
          <span class="ratings-block">
            <i
              v-for="index in 5"
              class="fa fa-star"
              v-bind:class="
                getGoldClass(
                  orderDetails.rider
                    ? parseInt(orderDetails.riderId.vendorRating)
                    : 0,
                  index
                )
              "
              :key="index"
            ></i>
          </span>
          <span class="ratings-value"
            >{{
              orderDetails.rider ? orderDetails.rider.vendorRating : "0"
            }}.0</span
          >
        </div>
        <div class="info-block order-info">
          <div class="product-info">
            No. of Products:
            <span>{{
              orderDetails.products ? orderDetails.products.length : 0
            }}</span>
          </div>
          <div class="bill-info">
            Bill:
            <span class="bill">${{ orderDetails.estimatedAmount }}</span>
          </div>
          <div class="delivery-info">
            Delivery Time:
            <span>{{
              orderDetails.orderDeliveryType
                ? orderDetails.orderDeliveryType
                : ""
            }}</span>
          </div>
        </div>
        <div class="info-block status-block">
          <div class="status-container">
            <img
              src="/media/dashboard/green_smiley@2x.png"
              alt="Green Smiley"
            />
            <span class="status-text">{{ orderDetails.orderPerformance }}</span>
          </div>
        </div>
      </div>
      <div class="order-info-details">
        <template v-if="!orderDetails.squadAdmin">
          <status-graph :order="orderDetails" />
        </template>
        <template>
          <status-graph-squad :order="orderDetails" />
        </template>
      </div>
      <div class="customer-details-container" v-if="orderDetails">
        <h3 class="heading">Customer Details</h3>
        <div class="customer-details-content">
          <div class="customer-details-block">
            <div class="detail-block">
              <div class="detail-heading">Customer Name</div>
              <div class="detail-text">
                {{ orderDetails.customer ? orderDetails.customer.name : "" }}
              </div>
            </div>
            <div class="detail-block">
              <div class="detail-heading">Date and Time</div>
              <div class="detail-text">
                {{ $moment(orderDetails.timePlaced).format("MMM Do, h:mm a") }}
              </div>
            </div>
            <div class="detail-block">
              <div class="detail-heading">Contact #</div>
              <div class="detail-text">
                {{ orderDetails.customer.phone }}
              </div>
            </div>
            <div class="detail-block">
              <div class="detail-heading">
                Address
                <span
                  @click="toggleMap"
                  style="text-decoration: underline; color: #4f72e3"
                  >(View map)</span
                >
              </div>
              <div class="detail-text">
                {{ orderDetails.shippingAddress.address }}
              </div>
            </div>
            <div class="detail-block">
              <div class="detail-heading">Product Not Found Prefrence</div>
              <div class="detail-text">
                {{ orderDetails.customer.returnPreference.prefOne }}
              </div>
            </div>
          </div>
          <div class="customer-details-block">
            <div class="detail-block">
              <div class="detail-heading">Zip Code</div>
              <div class="detail-text">
                {{ orderDetails.zipCode }}
              </div>
            </div>
            <div class="detail-block">
              <div class="detail-heading">Payment Method</div>
              <div class="detail-text">
                {{ orderDetails.paymentMethod }}
              </div>
            </div>
            <div class="detail-block">
              <div class="detail-heading">Promo Code</div>
              <div class="detail-text">
                {{ orderDetails.promoCode ? orderDetails.promoCode : "No" }}
              </div>
            </div>
          </div>
        </div>
        <div class="customer-details-content">
          <div class="customer-details-block">
            <div class="detail-block">
              <div class="detail-heading">Customer Note</div>
              <div class="detail-text">
                {{ orderDetails.orderNote }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="billing-container" v-if="showMap" style="position: relative">
        <h3 class="heading">
          <span
            style="
              position: absolute;
              top: 0px;
              left: 25px;
              padding: 5px;
              cursor: pointer;
            "
            @click="toggleMap"
          >
            <i class="fas fa-chevron-left"></i>
          </span>
          Live Map
        </h3>
        <rider-map />
      </div>
      <div class="products-details-container" v-if="!showMap">
        <h3 class="heading">Product Details</h3>
        <div class="product-details-row heading">
          <div class="column">S. No</div>
          <div class="column">Name</div>
          <div class="column">Quantity</div>
          <div class="column">Price per unit</div>
          <div class="column">Total Price</div>
          <div class="column"></div>
        </div>
        <div
          class="product-details-row details"
          :key="index"
          v-for="(item, index) in orderDetails.products"
        >
          <div class="column">{{ index + 1 }}</div>
          <div class="column">{{ item.productId.name }}</div>
          <div class="column">{{ item.quantity }}</div>
          <div class="column">${{ item.productId.price }}</div>
          <div class="column">${{ item.price }}</div>
          <div class="column">
            <div class="cat">{{ item.productType }}</div>
          </div>
        </div>
      </div>

      <div class="billing-container" v-if="!showMap">
        <h3 class="heading">Billing & Payments</h3>
        <div class="billing-content">
          <div class="billing-details details-section">
            <div class="billing-detail">
              <span class="heading">Estimated Total Bill</span>
              <span class="value">${{ orderDetails.orderAmount }}</span>
            </div>
            <div class="billing-detail">
              <span class="heading">Delivery Charges</span>
              <span class="value">${{ orderDetails.deliveryCharges }}</span>
            </div>
            <div class="billing-detail">
              <span class="heading">Admin Charges</span>
              <span class="value">${{ orderDetails.adminCharges }}</span>
            </div>
            <div class="billing-detail promo">
              <span class="heading">Promo Code</span>
              <span class="value">$0</span>
            </div>
            <div class="billing-detail">
              <span class="heading">Estimated Total</span>
              <span class="value"
                >${{
                  orderDetails.estimatedAmount
                    ? orderDetails.estimatedAmount.toFixed(2)
                    : "0"
                }}</span
              >
            </div>
            <div
              class="billing-detail confirmed"
              :class="orderDetails.riderEarning === 0 ? 'pending' : ''"
            >
              <span class="heading">Confirmed payment</span>
              <span class="value">{{
                orderDetails.riderEarning === 0 ? "Pending" : "Confirmed"
              }}</span>
            </div>
          </div>
          <div class="billing-details earning">
            <div class="earning-block">
              <div class="title">Baskyt Payment</div>
              <img src="/media/dashboard/baskytor_card.png" alt="Float cart" />

              <div class="details">
                ${{ orderDetails.adminEarning.toFixed(2) }}
              </div>
            </div>
            <div class="earning-block">
              <div class="title">Baskytor Earning</div>
              <div class="logo">
                <img src="/media/dashboard/float_cart.png" alt="Float cart" />
              </div>
              <div class="details">
                ${{ orderDetails.riderEarning.toFixed(2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RiderMap from "@/view/layout/dashboard_components/RiderMap";
import StatusGraph from "@/view/pages/orders/us/StatusGraph";
import StatusGraphSquad from "@/view/pages/orders/us/StatusGraphSquad";
import moment from "moment";
import { mapState } from "vuex";
export default {
  data() {
    return {
      lastseenNum: 1623782953450,
      showMap: false,
      customerNoteText: "",
      riderexist: false,
      retries: [],
      dropdown: false
    };
  },
  components: {
    RiderMap,
    StatusGraph,
    StatusGraphSquad
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },
  props: {
    orderDetails: Object
  },
  mounted() {
    console.log(
      "file: OrderModal.vue | line 373 | mounted | this.orderDetails.",
      this.orderDetails
    );

    // this.retries = this.orderDetails.retries;
    if (this.orderDetails.riderId._id !== "") {
      this.riderexist = true;
    } else {
      this.riderexist = false;
    }
  },

  methods: {
    getOrderStatus(status, num) {
      if (status === undefined) return;
      // pending, confirmed, assembling, picked up, delieverd
      if (status.toLowerCase() === "pending") {
        if (num === 1) {
          return "is-current";
        } else return "";
      } else if (status.toLowerCase() === "confirmed") {
        if (num === 1) return "is-complete";
        else if (num === 2) return "is-current";
        else return "";
      } else if (
        status.toLowerCase() === "assembling" ||
        status.toLowerCase() === "preparing"
      ) {
        if (num < 3) {
          return "is-complete";
        } else if (num === 3) {
          return "is-active";
        } else {
          return "";
        }
      } else if (status.toLowerCase() === "picked up") {
        if (num < 4) {
          return "is-complete";
        } else if (num === 4) {
          return "is-active";
        }
      } else if (status.toLowerCase() === "reached customer") {
        if (num < 5) {
          return "is-complete";
        } else if (num === 5) {
          return "is-active";
        }
      } else {
        return "is-complete";
      }
    },
    showDropdown() {
      if (this.dropdown) this.dropdown = false;
      else this.dropdown = true;
    },
    getProgessText(status) {
      if (status === undefined) return;
      if (status.toLowerCase() === "pending") {
        return "Finding Baskytor";
      } else if (status.toLowerCase() === "confirmed") {
        return "On the  way to the Store";
      } else if (
        status.toLowerCase() === "assembling" ||
        status.toLowerCase() === "preparing"
      ) {
        return "Assembling Products";
      } else if (status.toLowerCase() === "picked up") {
        return "On the way to Customer";
      } else {
        return "Delivered";
      }
    },
    toggleMap() {
      this.showMap = !this.showMap;
    },
    showOrderModal: function() {
      this.$bvModal.show("order-modal");
    },
    getGoldClass: function(total, key) {
      return key <= total ? "gold" : "";
    },
    hideOrderModal: function() {
      this.$bvModal.hide("order-modal");
      this.$emit("close");
    },
    humanizeDuration: function(duration) {
      return `In ${moment.duration(duration, "milliseconds").humanize()}.`;
    }
  }
};
</script>

<style lang="scss" scoped>
$gray: #9f9fa3;
$green: #3bb40c;
$orange: #ef8411;
::v-deep .modal-class {
  .no-Rider {
    // justify-content: center;
    text-align: center;
  }
  .modal-body {
    padding: 1.75rem 0;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dc1828;
    }
  }
  .modal-dialog {
    .close-btn {
      background: none;
      position: absolute;
      right: 25px;
      top: 15px;
    }
    min-width: 750px;
    .modal-content {
      border-radius: 25px;
      .order-type {
        width: 100%;
        display: flex;
        justify-content: center;
        .text-value {
          background: #ffafb1;
          color: #e90008;
          font-size: 14px;
          font-weight: 700;
          padding: 5px 15px;
          border-radius: 25px;
        }
      }
      .order-heading {
        border-bottom: 1px solid #818181;
        h3 {
          font-weight: 600;
        }
        p {
          font-size: 14px;
        }
      }
      .order-info-details {
        padding: 15px;
        border-bottom: 1px solid #818181;
        display: grid;
        grid-template-columns: repeat(3, auto);
        .data-cell {
          display: flex;
          &.order-amount {
            display: flex;
            width: 100%;
            .value {
              font-weight: 700;
              width: 75px;
              margin-left: 5px;
            }
            .dropdown-toggle {
              background-color: transparent !important;
              border: none !important;
              padding: 0px !important;
              color: white;
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          &.user-info {
            img {
              border-radius: 50%;
              border: 1px solid red;
              height: 75px;
              width: 75px;
              margin: 5px 0;
            }
            .ratings-block {
              margin: 5px 0;
              display: flex;
              i {
                margin: 5px 0;
              }
              i.gold {
                color: #ff9500;
              }
            }
            .ratings-value {
              margin: 5px 0;
            }
          }
          &.order-info {
            align-items: flex-start;
            font-weight: 700;
            justify-content: center;
            .product-info {
              padding: 5px;
            }
            .bill-info {
              padding: 5px;
              span {
                text-decoration: underline;
                font-weight: 400;
              }
            }
            .delivery-info {
              padding: 5px;
              span {
                font-weight: 400;
              }
            }
            .retries-info {
              padding: 5px;
              div {
                span {
                  font-weight: 400;
                }
              }
            }
          }
          &.progress-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .ProgressBar {
              width: 100%;
              margin: 0 auto;
              padding: 10px;
              list-style: none;
              position: relative;
              display: flex;
              justify-content: space-between;
            }
            .ProgressBar-step.is-complete + .ProgressBar-step.is-current:after,
            .ProgressBar-step.is-complete
              + .ProgressBar-step.is-complete:after {
              width: 100%;
            }
            .ProgressBar-step {
              text-align: center;
              position: relative;
              width: 100%;

              &:before,
              &:after {
                content: "";
                height: 3px;
                background-color: $gray;
                position: absolute;
                z-index: 1;
                width: 100%;
                left: -50%;
                top: 55%;
                transform: translateY(-50%);
                transition: all 0.25s ease-out;
              }

              &:first-child:before,
              &:first-child:after {
                display: none;
              }

              &:after {
                background-color: $green;
                width: 0%;
              }
            }
            .is-current .ProgressBar-icon {
              fill: $orange;
              background-color: $orange;
            }
            .is-complete .ProgressBar-icon {
              fill: #fff;
              background-color: $green;
            }
            .ProgressBar-icon {
              width: 20px;
              height: 20px;
              background-color: $gray;
              fill: $gray;
              border-radius: 50%;
              padding: 5px;
              max-width: 100%;
              z-index: 10;
              position: relative;
              transition: all 0.25s ease-out;
            }

            .ProgressBar-stepLabel {
              display: block;
              text-transform: uppercase;
              color: $gray;
              position: absolute;
              padding-top: 8px;
              width: 100%;
              transition: all 0.25s ease-out;
            }
            .progress-text {
              color: $green;
              text-align: center;
            }
            .progress-eta {
              font-weight: 700;
              font-size: 12px;
            }
          }
          &.status-block {
            padding: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            .status-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              img {
                height: 35px;
                margin: 5px;
                width: auto;
              }
            }
          }
        }
      }
      .customer-details-container {
        border-bottom: 1px solid #818181;

        padding: 25px;
        .heading {
          text-align: center;
        }

        .customer-details-content {
          display: flex;
          .customer-details-block {
            width: 50%;
            .detail-block {
              padding: 5px;
              font-size: 12px;
              display: flex;
              .detail-heading {
                font-weight: 700;
                width: 50%;
                span {
                  cursor: pointer;
                }
              }
              .detail-text {
                width: 50%;
              }
            }
          }
        }
      }
      .products-details-container {
        border-bottom: 1px solid #818181;

        padding: 25px;
        .heading {
          text-align: center;
        }
        .product-details-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          &.heading {
            font-weight: 700;
          }
          &.details {
            margin: 5px 0;
            .column {
              font-size: 12px;
              &:nth-child(2) {
                text-align: left;
              }
            }
          }
          .column {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-child(1) {
              text-align: left;
              justify-content: flex-start;
            }
            &:nth-child(2) {
              width: 125%;
            }
            .cat {
              padding: 5px;
              color: #019b25;
              background: #82f89d;
              border-radius: 25px;
              font-weight: 700;
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }
        }
      }
      .billing-container {
        padding: 25px;
        .heading {
          text-align: center;
        }
        .billing-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .billing-details {
            &.details-section {
              display: flex;
              flex-direction: column;
              .billing-detail {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                margin: 5px 2.5px;
                .heading {
                  font-weight: 700;
                }
                &.promo {
                  color: #e9424c;
                }
                &.confirmed {
                  color: #019b25;
                  background: #82f89d;
                  padding: 5px;
                  border-radius: 25px;
                  margin: 5px 0px;
                }
                &.pending {
                  background: transparentize($color: $orange, $amount: 0.7);
                  color: $orange;
                }
              }
            }
            &.earning {
              width: 100%;
              display: flex;
              align-items: center;
              .earning-block {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .title {
                  font-size: 12px;
                  font-weight: 700;
                }
                img.logo {
                  height: 30px;
                  width: auto;
                }
                .details {
                  font-size: 12px;
                  font-weight: 700;
                  color: #707070;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
