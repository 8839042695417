<template>
  <div class="orders-table">
    <div class="row pt-5">
      <div class="col-lg-6 py-3 d-flex align-items-center">
        <h3>{{ tableTitle }}</h3>
        <button
          @click="updateOrders"
          class="create-button"
          :class="fetchingRecords ? 'btn-disabled processing' : ''"
          :disabled="fetchingRecords"
        >
          Refresh
          <img
            class="ajax-loader"
            src="/media/ajax/white.gif"
            alt="Ajax loader"
          />
        </button>
      </div>
      <div class="col-lg-3">
        <div style="display: grid; place-items: center" class="w-100 h-100">
          <span><b>Total Records:</b> {{ rows }}</span>
        </div>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-end py-3"
        v-if="rows > 10"
      >
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center">Orders Per Page</span>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
      <div class="col-lg-12 table-container">
        <template v-if="true">
          <b-table
            id="active-orders-table"
            hover
            :items="tableOrders"
            :fields="fields"
            :busy="fetchingRecords"
            class="table-light"
            thead-class="customers-table-head shadow-sm"
            tbody-class="active-orders-table-body"
            @row-clicked="orderRowClicked"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mx-4"></b-spinner>
                <strong>Fetching...</strong>
              </div>
            </template>
            <template v-slot:cell(type)="data">
              <div
                class="data-cell type"
                v-bind:class="getTypeClass(data.value)"
              >
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(timePlaced)="data">
              <span> {{ $moment(data.value).format("MMM D, h:mm a") }}</span>
            </template>
            <template v-slot:cell(customer)="data">
              <span style="display: flex; width: 100%" class="name-data-block">
                <template v-if="Array.isArray(data.item.customer)">
                  <stacked-images
                    :images="data.item.customer"
                    type="order"
                    :tipId="`${data.item.order}-${data.item.index}`"
                  />
                </template>
                <template v-else>
                  <span class="image-block">
                    <img
                      :src="
                        `${data.item.customer.profile ||
                          '/media/users/user1.jpg'}`
                      "
                    />
                  </span>
                  <span
                    style="display: flex; flex-direction: column"
                    class="details-block"
                  >
                    <span class="user-name">{{ data.item.customer.name }}</span>
                    <span class="address">{{
                      data.item.customer.address
                    }}</span>
                    <span class="phone-number">{{
                      data.item.customer.phone
                    }}</span>
                  </span>
                </template>
              </span>
            </template>
            <template v-slot:cell(orderAmount)="data">
              <div class="data-cell order-amount">
                <span class="value">{{ data.value }}</span>
              </div>
            </template>
            <template v-slot:cell(retries)="data">
              <div class="data-cell order-amount">
                <span
                  :class="`${data.value.length === 0 ? 'green' : 'red'}`"
                  class="value"
                  >{{ data.value.length }}
                  <b-dropdown v-if="data.value.length > 0" id="dropdown-1">
                    <b-dropdown-item
                      v-for="(rider, index) in data.value"
                      :key="index"
                      >{{ rider.rider.name }}</b-dropdown-item
                    >
                  </b-dropdown></span
                >
                >
              </div>
            </template>
            <template v-slot:cell(Status)="data">
              <div class="data-cell status-cell" v-if="data.value === 'Good'">
                <img src="/media/dashboard/green_smiley.png" alt="Confirmed" />
              </div>
              <div
                class="data-cell status-cell"
                v-else-if="data.value === 'Late' || data.value == 'Very Late'"
              >
                <img src="/media/dashboard/red_exclamation.png" alt="Pending" />
              </div>
              <div class="data-cell status-cell" v-else>
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(vendors)="data">
              <stacked-images
                :images="data.value"
                :tipId="`${data.item.order}-stacks-${data.item.index}`"
                type="order"
              />
            </template>
            <template v-slot:cell(cancel)="data">
              <div>
                <b-button
                  class="CancelButtonCheck"
                  @click="cancelOrder(data.index)"
                  >Cancel Order</b-button
                >
              </div>
            </template>
            <template v-slot:cell()="data">
              <div class="data-cell">{{ data.value }}</div>
            </template>
          </b-table>
          <div class="col-lg-12 d-flex justify-content-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="changePage"
              aria-controls="active-orders-table"
              :current-page="currentPage"
            ></b-pagination>
          </div>
        </template>
        <template v-else>
          <div class="text-danger text-center display-4 p-4">API Error</div>
          <div class="text-danger text-center lead pb-4">
            Error while fetchting active orders. Please refresh to try again.
          </div>
          <div class="text-danger text-center lead pb-4">
            {{ networkError }}
          </div>
        </template>
      </div>
    </div>
    <template v-if="modalData !== null">
      <order-modal
        ref="orderModal"
        :orderDetails="modalData"
        @orderModalClosed="orderModalClosed"
      />
    </template>
    <template v-if="modalDataSquad !== null">
      <order-modal-squad
        ref="orderModalSquad"
        :orderDetails="modalDataSquad"
        @orderModalClosed="orderModalSquadClosed"
      />
    </template>
    <template v-if="cancelOrderConfirm >= 0">
      <b-modal
        id="modal-center"
        ok-title="Yes"
        @ok="handleOk"
        @cancel="stopCancelling"
        :ok-disabled="cancelling"
        :cancel-disabled="cancelling"
        ref="cancelOrderModal"
        content-class="AreYouSure"
      >
        <template v-if="cancelling">
          <b-spinner class="align-middle mx-4"></b-spinner>
        </template>
        <p class="my-4">Are you sure you want to cacel this order</p>
      </b-modal>
    </template>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
import OrderModal from "@/view/pages/orders/pk/OrderModal";
import OrderModalSquad from "@/view/pages/orders/pk/OrderModalSquad";
export default {
  props: {
    orderCall: String,
    tableTitle: String
  },
  name: "OrdersTablePK",
  components: {
    "stacked-images": StackedImages,
    "order-modal": OrderModal,
    OrderModalSquad
  },
  data: () => ({
    cancelOrderConfirm: -1,
    cancelling: false,
    acticeOrderindex: null,
    storeNamesToll: "",
    fetchingStats: true,
    modalData: null,
    modalDataSquad: null,
    perPage: 10,
    currentPage: 1,
    fields: [
      {
        key: "type",
        label: "Type",
        sortable: false
      },
      {
        key: "order",
        label: "Order",
        sortable: true
      },
      {
        key: "customer",
        label: "Customer",
        sortable: true
      },
      {
        key: "timePlaced",
        label: "Order Time",
        sortable: true
      },
      {
        key: "vendors",
        label: "Vendors",
        sortable: true
      },
      {
        key: "rider",
        label: "Rider",
        sortable: true
      },
      {
        key: "retries",
        label: "Retries",
        sortable: true
      },
      {
        key: "orderAmount",
        label: "Order Amount",
        sortable: true
      },
      {
        key: "performance",
        label: "Performance",
        sortable: true
      },
      {
        key: "cancel",
        label: "Cancel",
        sortable: true
      }
    ],
    perPageOptions: [
      { value: 10, text: "10" },
      { value: 25, text: "25" },
      { value: 50, text: "50" }
    ]
  }),
  computed: {
    ...mapState({
      orders: ({ activeOrders }) => activeOrders.orders,
      activeOrders: state => state.orders.activeOrders,
      fetchingRecords: ({ activeOrders }) => activeOrders.fetching,
      networkError: function(state) {
        return state.orders.activeOrdersError;
      },
      tableOrders: ({ activeOrders }) => {
        if (!activeOrders.orders || activeOrders.orders.length === 0) {
          return [];
        }
        console.log(
          "file: OrdersTable.vue | line 308 | activeOrders.orders",
          activeOrders.orders
        );
        return activeOrders.orders.map((item, index) => {
          const { orderModel, order, rider } = item;
          let squadOrder = null;
          if (orderModel === "squad") {
            squadOrder = order.deliveryRoute.find(s => s.rider._id === rider);
          }
          return {
            type: item.orderModel,
            order: orderModel === "squad" ? order.inviteId : order.orderId,
            customer:
              orderModel === "squad"
                ? squadOrder.customers.map(c => ({
                    name: c.customer.name,
                    image: c.customer.profileImage
                  }))
                : {
                    name: order.receiver.name,
                    image: order.consumer ? order.consumer.profileImage : null,
                    address: order.shippingAddress.address,
                    phone: order.consumer
                      ? order.consumer.phone
                      : order.receiver.phone
                  },
            timePlaced:
              orderModel === "squad" ? squadOrder.timeFound : order.time.placed,
            vendors:
              orderModel === "squad"
                ? [
                    {
                      image: order.store.storeLogo,
                      name: order.store.storeName
                    }
                  ]
                : order.stores.map(s => ({
                    image: s.image,
                    name: s.store.storeName
                  })),
            rider:
              orderModel === "squad"
                ? squadOrder.rider.name
                : order.rider
                ? order.rider.name
                : "No Rider",
            retries:
              orderModel === "squad" ? squadOrder.retries : order.retries,
            orderAmount:
              orderModel === "squad"
                ? `PKR${squadOrder.estimatedAmount}`
                : `PKR${order.amount.estimated}`,
            performance: "Good",
            index,
            cancel: orderModel === "squad" ? squadOrder._id : order._id
          };
        });
      },
      rows: ({ activeOrders }) => activeOrders.count
    })
  },
  mounted() {
    this.$store.dispatch(this.orderCall, [0, 10]);
  },
  methods: {
    ...mapGetters(["region"]),
    stopCancelling() {
      setTimeout(() => {
        this.cancelOrderConfirm = -1;
      }, 100);
    },
    cancelOrder(idx) {
      this.cancelOrderConfirm = idx;
      setTimeout(() => {
        this.$refs.cancelOrderModal.show();
      }, 200);
    },
    updateOrders: function() {
      // Method to update all the orders
      this.$store.dispatch(this.orderCall, [
        (this.currentPage - 1) * this.perPage,
        this.perPage
      ]);
    },
    perPageChange(val) {
      // Method to update orders on per page change
      this.$store.dispatch(this.orderCall, [(this.currentPage - 1) * val, val]);
    },
    changePage(val) {
      // Method to update orders on changing the page
      this.$store.dispatch(this.orderCall, [
        (val - 1) * this.perPage,
        this.perPage
      ]);
    },
    orderRowClicked(items) {
      // Method to handle the event on clicking the row on table
      const { orderModel, order, rider } = this.orders[items.index];
      if (orderModel === "squad") {
        const squadOrder = order.deliveryRoute.find(s => s.rider._id === rider);
        const infos = order.deliveryInfo.filter(s => s.riderId === rider);
        this.modalDataSquad = {
          ...squadOrder,
          store: order.store,
          deliveryInfo: infos,
          product: order.product,
          squadDiscount: order.squadDiscount,
          inviteId: order.inviteId
        };
        setTimeout(() => {
          this.$refs.orderModalSquad.showOrderModal();
        }, 100);
      } else {
        //  (this.modalData = this.orders[items.index])
        const nOrder = order; //.deliveryRoute.find(s => s.rider._id === rider);
        //const ninfos = order.deliveryInfo.filter(s => s.riderId === rider);
        this.modalData = {
          ...nOrder,
          store: order.stores[0],
          // deliveryInfo: ninfos,
          product: order.stores[0].products,
          //squadDiscount: order.squadDiscount,
          inviteId: order.orderId
        };
        setTimeout(() => {
          this.$refs.orderModal.showOrderModal();
        }, 100);
      }
    },
    orderModalClosed() {
      this.orderModal = null;
    },
    orderModalSquadClosed() {
      this.orderModalSquad = null;
    },
    getTypeClass(type) {
      // Getting the order type
      if (type === "squad") {
        return "squad-type";
      }
      return "normal-type";
    },
    async handleOk(e) {
      e.preventDefault();
      console.log(
        "file: OrdersTable.vue | line 439 | handleOk | this.cancelOrderConfirm",
        this.cancelOrderConfirm
      );
      const { order } = this.orders[this.cancelOrderConfirm];
      console.log("file: OrdersTable.vue | line 453 | handleOk | order", order);
      let orderId = null;
      let customerId = null;
      // if (orderModel !== "squad") {
      orderId = order._id;
      // customerId = order.customer._id;
      // }
      this.cancelling = true;
      await Promise.resolve(
        new Promise(resolve => {
          ApiService.post("order/cancelOrderAdmin", {
            orderId,
            customerId,
            cancelType: "admin_cancel",
            reason: "admin_cancel"
          })
            .then(res => {
              if (res.data.success) {
                this.updateOrders();
                this.$refs.cancelOrderModal.hide();
                setTimeout(() => {
                  this.cancelOrderConfirm = -1;
                  this.cancelling = false;
                  resolve(true);
                }, 100);
              } else {
                resolve(false);
              }
            })
            .catch(error => {
              console.log(error);
              resolve(error);
            });
        })
      );
    }
  }
};
</script>
